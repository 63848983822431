import residencyStore from '@/residency/store'
import workshopStore from '@/workshop/store/store.js'
import axios from 'axios'

const getters = {
  // This is here for the E2E tests that need to get a token to make a request without
  // actually needing to browse to the portal.
  getTokenInfo: (state) => {
    if (residencyStore.getters.getTokenInfo.accessToken) {
      return residencyStore.getters.getTokenInfo
    } else {
      return workshopStore.getters.getTokenInfo
    }
  }
}

const actions = {
  submitLogin: async ({ dispatch }, loginForm) => {
    loginForm.username = loginForm.username.trim()
    const residencyLoginReq = dispatch('requestToken', {
      loginForm,
      baseApi: process.env.VUE_APP_BASE_API
    })
    const workshopLoginReq = dispatch('requestToken', {
      loginForm,
      baseApi: process.env.VUE_APP_BASE_WORKSHOP_API
    })

    const [residencyLoginResp, workshopLoginResp] = await Promise.allSettled([
      residencyLoginReq,
      workshopLoginReq
    ])

    if (residencyLoginResp.status === 'fulfilled' && workshopLoginResp.status === 'fulfilled') {
      return {
        residency: residencyLoginResp.value.data,
        workshop: workshopLoginResp.value.data
      }
    } else if (residencyLoginResp.status === 'fulfilled') {
      return { residency: await dispatch('residencyLogin', residencyLoginResp.value.data) }
    } else if (workshopLoginResp.status === 'fulfilled') {
      return { workshop: await dispatch('workshopLogin', workshopLoginResp.value.data) }
    } else { // Else both requests have failed
      residencyStore.commit('CLEAR_AUTH_STATE')
      workshopStore.commit('CLEAR_AUTH_STATE')
      // If both requests failed, we want to rethrow the error with the most specific error message.
      // The scoreLoginError will return a priority score for the error, which is then used to compare
      // the responses errors. The error with the lowest score is the most specific error.
      const residencyResponse = residencyLoginResp.reason
      const workshopResponse = workshopLoginResp.reason
      const error = scoreLoginError(residencyResponse.response) < scoreLoginError(workshopResponse.response)
        ? residencyResponse
        : workshopResponse
      throw error
    }
  },
  requestToken: ({ commit }, { loginForm, baseApi }) => {
    const credentials = new URLSearchParams()
    credentials.append('username', loginForm.username)
    credentials.append('password', loginForm.password)
    credentials.append('grant_type', loginForm.grant_type)
    const options = {
      url: baseApi + '/oauth/token',
      method: 'POST',
      withCredentials: true,
      data: credentials,
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      auth: {
        username: process.env.VUE_APP_CLIENT_ID,
        password: process.env.VUE_APP_CLIENT_SECRET
      }
    }
    return axios(options)
  },
  workshopLogin: async ({ dispatch }, data) => {
    await workshopStore.dispatch('login', data)
    return workshopStore.getters.me
  },
  residencyLogin: async ({ dispatch }, data) => {
    await residencyStore.dispatch('login', data)
    return residencyStore.getters.me
  },
  resetPasswordEmail: async ({ dispatch }, email) => {
    // This must be done synchronously because if the email address is registered in both residency and workshop,
    // then the password would be reset twice, sending two emails with one of the tokens being invalid.
    let residencyErr = null
    try {
      const residencyResp = await residencyStore.dispatch('resetPasswordEmail', email)
      if (residencyResp.status === 204) {
        return residencyResp
      }
    } catch (err) {
      residencyErr = err
    }

    // If the email address is not found in residency, we want to try the workshop.
    let workshopErr = null
    try {
      const workshopResp = await workshopStore.dispatch('resetPasswordEmail', email)
      if (workshopResp.status === 200) {
        return workshopResp
      }
    } catch (err) {
      workshopErr = err
    }

    // If both requests failed, we want to rethrow the error with the most specific error message.
    // These endpoints can either return 404 or 403. 403 is more specific than 404,
    // so we want to prioritize that error.
    throw residencyErr.status < workshopErr.status
      ? residencyErr
      : workshopErr
  },
  resetPassword: async ({ dispatch }, { resetToken, password }) => {
    const [residencyResp, workshopResp] = await Promise.allSettled([
      residencyStore.dispatch('resetPassword', { resetToken, password }),
      workshopStore.dispatch('resetPassword', { resetToken, password })
    ])

    if (residencyResp.status !== 'fulfilled' && workshopResp.status !== 'fulfilled') {
      // These endpoints can return 404 or 422. 422 is more specific than 404,
      // so we want to prioritize that error.
      throw residencyResp.response.status > workshopResp.response.status
        ? residencyResp
        : workshopResp
    }
  },
  clearAllStates: () => {
    residencyStore.dispatch('clearState')
    workshopStore.dispatch('clearWorkshopState')
  }
}

/**
 * This function scores the error based on the status code and error description.
 * The lower the score, the higher the priority. The highest priority errors
 * are the ones that are more specific.
 *
 * @param {Object} errorResponse - The error response object returned by Axios
 * @returns int - score for the error
 */
const scoreLoginError = (errorResponse) => {
  const statusCode = errorResponse.status
  const errorDescription = errorResponse.data.error_description

  // If one of the services is down, we want to prioritize that error
  // regardless of how the other request failed.
  if (statusCode === 500) {
    return 0
  }

  // The user will be locked out of both residency and workshop,
  // if they have too many failed login attempts
  if (statusCode === 400 && errorDescription.includes('User account is locked')) {
    return 25
  }

  // If the user is disabled, we want to prioritize that error
  // over 'Bad credentials' from the other service
  if (statusCode === 400 && errorDescription.includes('User is disabled')) {
    return 50
  }

  // If the user doesn't have an active program or the role is under maintenance
  if (statusCode === 404 || statusCode === 401) {
    return 75
  }

  // Bad credentials is the most generic error, so we want keep it as the last priority
  if (statusCode === 400 && errorDescription.includes('Bad credentials')) {
    return 100
  }

  return 1000
}

export default {
  getters,
  actions
}
